<template>
  <div class="confirmBox" @click.stop='closeBox(false)'>
    <div class="content" @click.stop=''>
      <!-- <div class="topIcon">
        <i></i>
        <i></i>
        <i></i>
      </div> -->
      <p class="title" >
        <img src="@/assets/icon/close.png" alt="" @click='closeBox(false)'>
        <span>{{title}}</span>
      </p>
      <p class="time" v-if="content" v-html="content"></p>
      <slot name='slotCon'></slot>
      <div class='foodBtnBox'>
        <div class='finishBtn' @click.stop="closeBox(true)">{{ finishBtn }}</div>
        <div class='cancelBtn' @click.stop="closeBox(false)">{{ cancelBtn }}</div>
      </div>
      <!-- <div class="btn">
        <span v-if="showCancelBtn" @click.stop="closeBox">{{ cancelBtn }}</span>
        <span @click="finish">{{ finishBtn }}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "ConfirmBox",
  props: {
    size: {
      type: Number,
      default: 16,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    showCancelBtn: {
      type: Boolean,
      default: true,
    },
    finishBtn: {
      type: String,
      default: "確定",
    },
    cancelBtn: {
      type: String,
      default: "取消",
    },
    submitCallback: {
      type: Function // 确认事件
    },
    cancelCallback: {
      type: Function // 取消事件
    }
  },
  methods: {
    closeBox(type) {
      this.$emit("closeBox",type);
      // js 封装 勿动
      if (this.cancelCallback) this.cancelCallback()
    }
  },
};
</script>
<style scoped>
.foodBtnBox{
  font-size: 16px;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.foodBtnBox .finishBtn{
  width: 2.68rem;
  height: 0.4rem;
  line-height: 0.4rem;
  background: linear-gradient( 86deg, #FE1112 0%, #E4022B 100%);
  border-radius: 0.08rem;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 0.16rem;
}
.foodBtnBox .cancelBtn{
  width: 2.68rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius:  0.08rem;
  border: 1px solid #E4022B;
  text-align: center;
  color: #E4022B;
  margin-bottom: 0.25rem;
}
.confirmBox {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
.content {
  width: 3rem;
  background: #ffffff;
  border-radius: 0.12rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  font-weight: bold;
  color: #0F0F0F;
  line-height: 0.24rem;
  font-size: 0.18rem;
}
.logo {
  width: 1.56rem;
  height: 0.92rem;
}
.time {
  /* font-family: PingFang HK; */
  font-weight: bold;
  color: #0F0F0F;
  line-height: 0.24rem;
  margin:0;
  margin-bottom: 0.32rem;
  font-size: 0.18rem;
  padding-left:0.14rem;
  padding-right:0.16rem;
}
.title {
  font-size: 18px;
  /* font-family: PingFang HK; */
  /* font-weight: 500; */
  /* line-height: 25px; */
  /* color: #03060d; */
  /* margin-top: .2rem; */
  /* margin-bottom: .16rem; */
  padding:0.22rem 0rem  0.24rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title img{
  width:0.155rem;
  height:0.155rem;
  float: left;
  margin-left: 0.28rem;
  flex:0 0 0.155rem;
}
.title span{
  flex:1;
  text-align: center;
  padding-right: 0.435rem;
}
.btn {
  height: 0.54rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.54rem;
  color: #b1b1b3;
  border-top: 1px solid #cacbcc;
}
.btn span {
  width: 100%;
  text-align: center;
}
.btn span:first-child {
  border-right: 1px solid #cacbcc;
}
.btn span:last-child {
  color: #e4022b;
}

.orderAlert {
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: 0.2rem;
  color: #474747;
  padding: 0 0.24rem;
  box-sizing: border-box;
}
.orderAlert p {
  text-align: left;
  margin-top: 0.04rem;
}
.alertTitle {
  margin-bottom: 0.08rem;
  color: #03060d;
  font-size: 0.16rem;
}
.orderAlert p span {
  width: 0.05rem;
  height: 0.1rem;
  display: inline-block;
  border-right: 0.01rem solid #e4022b;
  border-bottom: 0.01rem solid #e4022b;
  transform: rotate(45deg);
}

.topIcon{
  display: flex;
  justify-content: center;
  align-items: center;
}
.topIcon i{
  width: .08rem;
  height: .1rem;
  background-color: #e4022b;
  margin-left: .08rem;
}
</style>
