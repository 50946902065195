import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import Icon from "./components/icon"
import {postPaas} from "./assets/utils/request";
import './assets/css/common.css';
import {getAppParams} from "./utils/jsBridge";
import {isKfcApp } from "./utils/common";




import './utils/log'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
(async function() {

const app = createApp(App)
// window.ongetAppParams=(data)=>{
//   try{
//     if(data){
//       let appData_ =  decodeURIComponent(data)
//       let appData = JSON.parse(appData_)
//       console.log(appData,'appData1111')
//       store.commit("setAreaData",'MO');
//       store.commit("saveArea",1);
//     }
//   }catch(e){
//     console.error(e)
//   }
//   // console.log(data,'---------------a',JSON.parse(testData))
// }

app.component("Icon",Icon);
let isDev = process?.env?.NODE_ENV === 'development'
if (!isDev) {
  try {
    const href = window.location.hostname;
    let keyUrl,environment
    if(href.indexOf('uat')>-1) {
      keyUrl = "https://edc79320d9334c28ba3cfbfbb1216523@o4504921309642752.ingest.sentry.io/4504921737658368";
      environment = "UAT"
    } else if(href.indexOf('www.kfc.com.hk')>-1
      ||href.indexOf('ordering.kfchk.com')>-1
      ||href.indexOf('ab-v6-h5-kfc.can-dao.com.hk')>-1
      ||href.indexOf('ab-v6-h5-kfc-app.can-dao.com.hk')>-1){
        keyUrl = "https://3e92de17007f46098bb88f611f377f4f@o4504921309642752.ingest.sentry.io/4504921733988352"
        environment = "PRO"
    } else if (href.indexOf('ab-v6-qc-h5-v6-kfc.can-dao.com')>-1 || href.indexOf('ab-v6-beta-h5-v6-kfc.can-dao.com')>-1) {
      keyUrl = "https://9a8bf4f6541b406bbca99909292bb81a@o4504251537883136.ingest.sentry.io/4504251558461440"
      environment = "SIT"
    } else {
      keyUrl = "https://9a8bf4f6541b406bbca99909292bb81a@o4504251537883136.ingest.sentry.io/4504251558461440"
      environment = "DEV"
    }
    const publicPath = window.__webpack_public_path__;
    Sentry.init({
      app,
      dsn: keyUrl,//根据自己的地址填写
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
      ],
      environment: environment,//推送具体开发环境，后台端便可根据环境进行筛选
      release: publicPath, // 版本号
      tracesSampleRate: 1.0, // 采样率
      ignoreErrors: [
        // Random plugins/extensions
        "maps",
        "https://www.googleapis.com/",
        "https://connect.facebook.net",
        "logId, msg, serverTime, status",
        "Couldn't resolve component"
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        // "https://maps.googleapis.com"
      ],
    });

    // 在发送前对事件进行修改
    Sentry.configureScope((scope) => {
      scope.addEventProcessor((event,hint) => {
        // console.log(scope,event,hint,3333333);
        if (event.level === 'error' || event.level === 'warning' || event.level === 'info') {
          if (hint?.originalException?.request?.status !== 200 || hint?.originalException?.request?.status !== 1 || hint?.originalException?.request?.status !== 9 || hint?.originalException?.request?.status !== 301) {
            event.tags = { level: "error" }
          } else if (hint?.originalException?.request?.status === 9 || hint?.originalException?.request?.status === 301) {
            event.tags = { level: "info" }
          } else {
            event.tags = { level: event.level }
          }
          return event
        }
      });
    });

    // 添加全局错误处理程序
    app.config.errorHandler = function (error,vm,info) {
      Sentry.withScope((scope) => {
        // 在当前范围下设置一些 Tags
        scope.setTag('tab', 'home');
        scope.setTag('env', process.env.NODE_ENV);

        if (vm) {
          scope.setExtra('component', vm.$options.name);
        }
        if (info) {
          scope.setExtra('info', info);
        }
        Sentry.captureException(error);
      });
      let u = navigator.userAgent.toLowerCase();
      let device
      if (u.includes("iphone") || u.includes("android")) {
        device = "h5"
      } else if (u.includes("KFC-APP")) {
        device = "app"
      } else {
        device = "web";
      }
      Sentry.setTag("foundation", device);
    };
  } catch (error) {
    console.log(error);
  }
}
if(isKfcApp()){
  let appData = await getAppParams();
  console.log('初始化接口获取app数据',appData)
  if(appData){
    localStorage.setItem("locationCity",appData?.brand||'KFCHK')
    store.commit("setAreaData",appData?.brand||'KFCHK');
    let area = appData?.brand==='KFCMO'?1:0
    store.commit("saveArea",area);
    try{
      if(localStorage.getItem("store")){
        let storeData = JSON.parse(localStorage.getItem("store"))
        storeData.area = area
        localStorage.setItem("store",JSON.stringify(storeData))
      }else{
        localStorage.setItem("store",JSON.stringify({area:area}))
      }
      console.log(JSON.parse(localStorage.getItem("store")),'缓存数据')
    }catch(e){
      console.error(e)
    }
  }
} else {
  let ipcityData_ = localStorage.getItem("ipcity")||'{ "region":"KFCHK", "ip":"defaultIp"}'
  let ipCityData = JSON.parse(ipcityData_)
  try{
    let getIpRegionparams = {
      actionName: " candao.statistics.getIpRegion",
      content: {
        platformKey:'a1b9f2aca1150632',
      },
    };
    let res = await postPaas("Action", getIpRegionparams)
    if(res?.data?.region){
      let ips = (res?.data?.ip||'').split(',')
      let ip = ips[0]||''
      if(ipCityData?.ip!=ip){
        ipCityData.ip = ip||''
        ipCityData.isConfirm = false
        if(res?.data?.region=='MO'){
          ipCityData.region = 'KFCMO'
        }
      }
      
    }
    localStorage.setItem("ipcity",JSON.stringify(ipCityData))
}catch(e){
  console.error(e)
  localStorage.setItem("ipcity",JSON.stringify(ipCityData))
}
  let brand = localStorage.getItem("locationCity")||'KFCHK'
  store.commit("setAreaData", brand);
  store.commit("saveArea", brand==='KFCMO'?1:0);
}
let getFrontConfigparams = {
  actionName: " candao.base.getFrontConfig",
  content: {
    platformKey:'a1b9f2aca1150632',
  },
};
// var dcParams = {
//   actionName: "candao.user.logout",
//   content: {},
// };

try{
  // postPaas("Action", dcParams).then(result=>{
  //   if(result&&result.status==1){
       // 先获取GA等相关配置，再加载页面
      localStorage.setItem("getFrontConfig",null)
      postPaas("Action", getFrontConfigparams).then(result=>{
        if(!isKfcApp()){
          let brand = localStorage.getItem("locationCity")||'KFCHK'
          if(brand==='KFCMO'&&(!result?.data?.configs?.isOpenMo)){
            localStorage.setItem("locationCity",'KFCHK')
            store.commit("setAreaData", 'KFCHK');
            store.commit("saveArea", 0);
            // localStorage.setItem("store", JSON.stringify(this.$store.state))
            location.reload()
          }
        }
        localStorage.setItem("getFrontConfig",result?.data?.configs?JSON.stringify(result?.data?.configs):null)
        app.use(store).use(router).use(Vant).mount('#app')
      });
  //   }else{
  //     app.use(store).use(router).use(Vant).mount('#app')
  //   }
  // })
}catch(err){
  app.use(store).use(router).use(Vant).mount('#app')
}
})()


// app.use(store).use(router).use(Vant).mount('#app')
