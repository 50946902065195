/*
 * @Author: lofty he
 * @Date: 2022-11-9 10:43:00
 * @LastEditTime: 2023-10-11 14:51:00
 * @LastEditors: lofty he
 * @Description: 公共方法模块
 * @FilePath: JRG-KFC\src\utils\common.js
 */
import store from './../store'
import {postDC} from "@/assets/utils/request";
import {postDataToApp } from "@/utils/jsBridge";


/**
 * @description: 浏览器信息获取
 * @param {}
 */
export const getBrowserType = () =>{
  if (store.state.isYuu || store.state.app) {
    // 判断是否app
    return 'InAppBrowser'
  } else {
    const explorer = window.navigator.userAgent.toLowerCase()
    if (!!window.ActiveXObject || "ActiveXObject" in window) { //判断是否为IE浏览器
      return "IE"
    } else if (explorer.includes("firefox")) { //是否为Firefox浏览器
      return "Firefox"
    } else if (explorer.includes("chrome")) { //是否为Chrome浏览器
      return "Chrome"
    } else if (explorer.includes("opera")) { //是否为Opera浏览器
      return "Opera"
    } else if (explorer.includes("safari")) { //是否为Safari浏览器
      return "Safari"
    }
  }
  return 'Unknown browser'
}

/**
 * @description: h5设备信息获取
 * @param {}
 */
export const getDevice = () => {
  let deviceId = 'Unknown device';
  let u = navigator.userAgent.toLowerCase();
  if (getBrowserType() === 'InAppBrowser') {
    if (u.includes("iphone") || u.includes("ipad")) {
      deviceId = "IOS"
    } else {
      deviceId = "AND" // 有风险
    }
  } else {
    if (u.includes("iphone") || u.includes("ipad")) {
      deviceId = "IOS"
    } else if (u.includes("android")) {
      deviceId = "AND"
    } else if (u.includes("mac") || u.includes("windows")) {
      deviceId = "WIN";
    }
  }
  return deviceId
}

/**
 * @description: JS获取UTC时间及各时区本地时间
 * @param {Number} // i：要得到那个时区的时间就传几，东区为正数，西区为负数
 * @param // 东八区-北京时间：" + getLocalTime(8)
 * @param // 西八区-太平洋时间（美国和加拿大）：" + getLocalTime(-8)
 */
export const getLocalTime = (i) => {
  let d = new Date(); 
  let len = d.getTime();
  //本地时间与UTC时间的时间偏移差
  let offset = d.getTimezoneOffset() * 60000;
  //得到现在的UTC时间，各时区UTC时间相同
  let utcTime = len + offset;
  //得到时区标准时间
  return new Date(utcTime + 3600000 * i);
  //得到UTC时间戳
  //return new Date(utcTime).getTime();
  //得到时区时间戳
  // return new Date(utcTime + 3600000 * i).getTime();
}

/**
 * @description: 禁止滚动
 * @param {} 
 */
export const stopScroll = () =>{
  var mo=function(e){e.preventDefault();};
  document.body.style.overflow='hidden';
  document.addEventListener("touchmove",mo,false);//禁止页面滑动
}
/**
 * @description: 取消滑动限制
 * @param {} 
 */
export const canScroll = () =>{
  var mo=function(e){e.preventDefault();};
  document.body.style.overflow='';//出现滚动条
  document.removeEventListener("touchmove",mo,false);
}

/**
 * @description: 判断有多少字符
 * @param {string} str
 * @return {number}
 */
export const strlen = (str) => {
  let len = 0;
  for (var i = 0; i < str.length; i++) {
    let c = str.charCodeAt(i);
    //单字节加1
    if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
      len++;
    } else {
      len += 2;
    }
  }
  return len;
}

/**
 * @description: 根据userAgent判断是否为app
 */
export const isKfcApp = () => {
  let uA = navigator.userAgent;
  if(uA.indexOf('KFC-APP')>-1){
    return true
  }else{  
    return false;
    // 用于本地调试app
    // return true;
    
  }
}

export const checkIosSystem = () => {
  const UA = navigator.userAgent
  const iOS = /iPad|iPhone|iPod/.test(UA)
  if (iOS) {
    const version = UA.match(/OS (\d+)_(\d+)_?(\d+)?/)
    const majorVersion = parseInt(version[1], 10)
    console.log('IOS 系统版本', majorVersion)
    return majorVersion
  }
  return -1
}

/** 是否从「支付宝」打开H5 */
export const isInAlipayClient = () => {
  const browser = navigator.userAgent.toLowerCase()
  return browser.match(/Alipay/i) == 'alipay'
}

/** 是否从「微信」打开H5 */
export const isInWechatClient = () => {
  const browser = navigator.userAgent.toLowerCase()
  return browser.match(/MicroMessenger/i) == 'micromessenger'
}

/**
 * 动态加载js
 */
let timeout = null
export const loadScript = (src, time = 20 * 1000) => {
  return new Promise((resolve, reject) => {
    clearTimeoutLoadScript()

    const script = document.createElement('script')
    const head = document.getElementsByTagName('head')[0]
    script.type = 'text/javascript'
    script.src = src
    if (script.addEventListener) {
      script.addEventListener('load', () => {
        clearTimeout(timeout)
        resolve()
      }, false)
    } else if (script.attachEvent) {
      script.attachEvent('onreadystatechange', () => {
        const target = window.event.srcElement;
        clearTimeout(timeout)
        if (target.readyState === 'loaded') {
          resolve()
        }
      });
    }

    timeout = setTimeout(() => {
      if (!window.google) {
        reject()
      }
    }, time)
    head.appendChild(script)
  })
}
export const clearTimeoutLoadScript = () => {
  if (timeout) {
    clearTimeout(timeout)
    timeout = null
  }
}
/**
 * 获取系统配置
 * @returns {Promise<void>}
 */
export const getSystemConfig = async (key = '') => {
  const params = {
    actionName: 'candao.base.getFrontConfig',
    content: {
      platformKey:'a1b9f2aca1150632'
    }
  }
  if (key) {
    params.content.key = key
  }

  try {
    const {data} = await postDC('Action', params)
    return data
  } catch (e) {
    console.error(e)
    return {}
  }
}
/**
 * @description: 跳转app判断正式和uat
 * @param {string} type
 */
export const getUrlApp = async (type) =>{
  // kfchk//index:   goOrder 去订单    
  // Kfchk//exith5   closeWebView
  try{
    if(type.indexOf('index')>-1){
      await store.commit('clearCouponInfo', null)
      postDataToApp('goOrder')
    }else if(type.indexOf('exith5')>-1){
      await store.commit('clearCouponInfo', null)
      postDataToApp('closeWebView')
    }
    return '';
  } catch(err){
    let getFrontConfigData = localStorage.getItem("getFrontConfig")
    if(JSON.parse(getFrontConfigData)?.ownEnv == "UAT"){
      return "kfchkuat://" + type
    } 
    if(JSON.parse(getFrontConfigData)?.ownEnv == "ONLINE") {
      return "kfchk://" + type
    }
    return "kfchk://" + type
  }
}

/**
 * @description: 获取月份简写
 * @param {string} type
 */
export const getConvertMont = (type) =>{
  switch (type) {
    case '01':
      return 'Jan'
    case '02':
      return 'Feb'
    case '03':
      return 'Mar'
    case '04':
      return 'Apr'
    case '05':
      return 'May'
    case '06':
      return 'Jun'
    case '07':
      return 'Jul'
    case '08':
      return 'Aug'
    case '09':
      return 'Sep'
    case '10':
      return 'Oct'
    case '11':
      return 'Nov'
    case '12':
      return 'Dec'
    default:
      break
  }
}

/**
 * @description: 获取周类型
 * @param {string} type
 */
export const getWeekType = (type) =>{
  switch (type) {
    case 1 :
      return store.state.language == 1 ? '週一' : 'Mon'
    case 2:
      return store.state.language == 1 ? '週二' : 'Tue'
    case 3:
      return store.state.language == 1 ? '週三': 'Wed'
    case 4:
      return store.state.language == 1 ? '週四': 'Thur'
    case 5:
      return store.state.language == 1 ? '週五': 'Fri'
    case 6:
      return store.state.language == 1 ? '週六': 'Sat'
    case 7:
      return store.state.language == 1 ? '週日': 'Sun'
    default:
      break
  }
}

export const  isHaveTablewareAndClear  = () =>{
  let isTipChange = false
  let cart = store.state?.cart||[];
  cart.forEach(item=>{
    if(item?.isTableware&&item?.tablewareSelectedType===1){
      isTipChange = true
      let productObj = {};
      let product = item;
      product.num = 0;
      productObj.product = product;
      productObj.cart = true;
      store.commit("createCart", productObj);
    }
  })
  return isTipChange

}
