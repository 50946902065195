export const AREA_LIST = [
  {
    name: '香港',
    nameEn:'HK',
    code: '852'
  },
  {
    name: '澳门',
    nameEn:'MO',
    code: '853'
  }
]

/**
 * 地区映射表
 * @date 2024-10-12
 * @returns {any}
 */
export const ATEA_MaAPPINF_LIST = {
  'KFCHK':{
    key:'40a443f1ced7f597',
    brandId:41,
    phoneCode:'852'

  },
  'KFCMO':{
    key:'197edf575db6dec0',
    brandId:42,
    phoneCode:'853'
  }
}
/**
 * 获取当前地区的映射关系
 * @date 2024-10-12
 * @param {any} key
 * @returns {any}
 */
export const getCurrenAreaData = (key = 'KFCHK') => {
  if(ATEA_MaAPPINF_LIST[key]){
    return ATEA_MaAPPINF_LIST[key]
  }else{
    return {
      key:'40a443f1ced7f597',
      brandId:41,
      phoneCode:'852'
  
    }
  }
}

export const getDefaultLocation = (key = 'KFCHK') => {
  if(key==='KFCMO'){
    return {lat:22.197749018621685, lng:113.54075759623527}
  }else{
    return {lat:22.3192, lng:114.1693}

  }
  
}

