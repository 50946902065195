import axios from "axios";
import store from '../../store'
import { getDevice } from '@/utils/common'
import {
	Toast,
	Dialog
} from 'vant';
Toast.allowMultiple();
let loadingArr = []
var baseURL;
var qcTest = false;

var href = window.location.href.split("/")
baseURL = href[0] + "//"
for (var i = 1; i < href.length; i++) {
	baseURL += href[i]
	if (href[i]) {
		break
	}
}
const sellerKey = qcTest ? "2a84992f8e86d642" : store.state.currenMappingData.key

var deviceId = getDevice()
// Paas

export function getSellerKey() {
	return sellerKey
}
function getKeyData () {
  let brand = localStorage.getItem("locationCity")||'KFCHK'
  let key = brand==='KFCMO'?'197edf575db6dec0':'40a443f1ced7f597'
  return key
}

let actionName;

// 2024-06-04 跟版本（20240606）,添加特殊判断，以规避以往留下来的逻辑问题
const spacialActions = [
	"candao.member.register",
	"candao.member.registerVerifySms"
]

const requestPaas = axios.create({
	baseURL,
	timeout: 30000,
	withCredentials: true,
})
// var loading;
// 请求拦截器
requestPaas.interceptors.request.use(
	config => {
		config.url += "?_name=" + config.data.actionName
		if (store.state.app) {
			deviceId = store.state.deviceId
		}
		actionName = config.data.actionName
		config.data.key = getKeyData();
		config.data.content.crmCode = "azure";
		config.data.content.source = "Web";
		if (config.data.content.jrgAzureParams) {
			config.data.content.jrgAzureParams.deviceId = deviceId
			config.data.content.jrgAzureParams.crmCode = "azure";
			config.data.content.jrgAzureParams.brand = "KFCHK";
			config.data.content.jrgAzureParams.source = "Web";
		}
		config.data.content.brand = localStorage.getItem("locationCity")||'KFCHK';
		config.data.content.deviceId = deviceId
		if (config.data.content.device && !config.data.content.selfSetDeviceData) { // 并且取自己定义的值  eg: 接口queryPayMethod
			config.data.content.device = deviceId
		}
		config.data.langType = store.state.language == 2 ? 2 : 1
		if (store.state.language == 2) {
			config.data.content.language = "E"
		} else {
			config.data.content.language = "TC"
		}
		if (store.state.area == 1) {
			config.data.content.brand = localStorage.getItem("locationCity")||'KFCHK'
		}

		// ||actionName=='candao.member.yuuCardInfoUnbind'
		// ||actionName=='candao.member.yuuLoginUrl'
		if(actionName=='candao.member.yuuLoginStatus'){
			delete config.data.content.source;
		}
		if (store.state?.isShowLoading 
			&& actionName != 'candao.base.printFELogger' 
			&& actionName != 'candao.preferential.canPreList') {
			// &&actionName!='candao.member.yuuVoucherGet'
			// &&actionName!='candao.member.yuuLoginStatus'
			// 如果接口无需loading，则增加传参isHideLoading=true
			if(config.data.content.isHideLoading){
				delete config.data.content.isHideLoading;
			}else{
				loadingArr.push(Toast.loading({
					forbidClick: true,
					duration: 0
				}));
			}
			
		}
		const userAgent = window.navigator.userAgent;
    config.data.userAgent = userAgent;

    // system取值为APP/WEB，pass处理把data对象里的system放到content里面传给azure
    config.data.system = userAgent.indexOf('KFC-APP') > -1?'APP':'WEB';
    if (store.state.isYuu) {
      config.data.system = 'APP'
    }
		
		return config

	}, error => {
		return Promise.reject(error)
	}
);
// 响应拦截器
requestPaas.interceptors.response.use(
	async res => {
		if (loadingArr.length) {
			loadingArr.splice(0, 1)[0].clear()
		}

		// 添加专门的接口判断 -- Nardy
		const urlOpts = new URL(res.config.url, location.origin || location.href)
		const reqActionName = urlOpts.searchParams.get("_name").trim()
		if (+res?.data?.status === 301 && spacialActions.includes(reqActionName)) {
			res.data.msg && Toast(res.data.msg)
			return Promise.reject(res.data)
		}

		if (res?.data?.status == 1) {
			return res.data
		} else {
			let isToast = (res.config.url.indexOf('candao.member.userUpdate') > -1 || res.config.url.indexOf('candao.member.yuuVoucherGet') > -1 || res.config.url.indexOf('candao.member.yuuLoginStatus') > -1) && res?.data?.status == 301;

			// !!!!!!!!!!! TODO: 以下判断条件中的actionName为全局变量，因为axios是异步请求响应操作，所以这里有非常大的概率会出现逻辑BUG~~!!!!!!!!!!!!!!!!!!!!!!!!!
			if ((
					(actionName == 'candao.member.yuuLoginStatus' || actionName == 'candao.member.yuuProfileGet' || actionName == 'candao.member.userUpdate' || actionName == 'candao.member.logout' || actionName == 'candao.member.getShopQuoTime' || actionName == 'candao.member.yuuVoucherGet')
					&& res?.data?.status == 301
				)
				|| isToast) {
				return res.data
			} else {
				if (res?.data?.status == 301) {
					if (res.data.msg == "登錄時間已過期，重新輸入 。 \r\n(錯誤代碼：400)" || res.data.msg == "Login session expired, please try again. (Code 400) ") {
						store.state.loginGet = 2
					} else if (res.data.msg.indexOf('重新登入') > -1 || res.data.msg.indexOf('login again') > -1) {
						Toast(res.data.msg)
						store.state.loginGet = 2
					} else {
						if (res?.config?.url && res.config.url.indexOf('candao.member.yuuLoginStatus') > -1) {
							return Promise.reject(res.data)
						}
						if (res?.config?.url && res.config.url.indexOf('candao.member.yuuProfileGet') > -1) {
							return Promise.reject(res.data)
						}
						if (res?.config?.url && res.config.url.indexOf('candao.member.register') > -1) {
							return Promise.reject(res.data)
						}
						if (res?.config?.url && res.config.url.indexOf('candao.member.voucherPromoCodeGet') > -1) {
							return Promise.reject(res.data)
						}
						// 判断逻辑没有进入
						// if(res?.config?.url&&['candao.member.yuuLoginStatus','candao.member.yuuProfileGet','candao.member.register'].includes(res.config.url)){
						// 	return Promise.reject(res.data)
						// }

						Toast(res.data.msg)
						return Promise.reject(res.data)
					}

					setLog({
						sign: 'Azure Error [301]',
						detail: res
					}, 'info')
				} else if (res?.data?.status == 2) {
					if (res.data.msg == "secretKey错误") {
						store.state.loginGet = 3
						return
					}
					var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
					// var btn = store.state.language == 2 ? "Confirm" : "確認"
					Toast(errorTest)
					// Dialog.alert({
					// 	message: errorTest,
					// 	confirmButtonText: btn
					// }).then(() => {
					// 	window.location.reload();
					// 	// on close
					// });

					setLog({
						sign: 'Azure Error [2]',
						detail: res
					}, 'info')

					return Promise.reject(res.data)
				} else {
					setLog({
						sign: 'Azure Error [null]',
						detail: 'RequestPaas response is ' + res
					}, 'error')

					return res?.data || {}
				}
			}
		}
	},
	error => {
		// setLog3(error, 1)
		// if(window.refreshWeb) {
		// 	return
		// }
		if(error?.config && error.config.url.indexOf('candao.base.printFELogger')) {
			return
		}

		// setLog(error)

		var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
		var btn = store.state.language == 2 ? "Confirm" : "確認"
		// Toast(errorTest)
		Dialog.alert({
			message: errorTest,
			confirmButtonText: btn
		}).then(() => {
			// on close
			window.location.reload();
		});
		if (loadingArr.length) {
			loadingArr.splice(0, 1)[0].clear()
		}

		return Promise.reject(error)
	}
)
// post请求
export function postPaas(url, params, headers = {}) {
	// url="/api/"+url
	if (!params.content.deviceId) {
		params.content.deviceId = deviceId
	}
	return requestPaas.post(
		url,
		params, {
			headers: {
				'content-type': 'multipart/form-data',
				...headers
			},
		}
	)
}

// const setLog3 = (error, num = 3) => {
// 	// TEST
// 	const paramssPrint = {
// 		actionName: "candao.base.printFELogger",
// 		content: {
// 			logLevel:'error',
// 			content: {
// 				error,
// 				sign: '接口异常: error ' + num,
// 				signDc: num === 1 ? 'azure' : 'candao-DC',
// 				href: window.location.href
// 			}
// 		},
// 	}
// 	paramssPrint.content.content = JSON.stringify(paramssPrint.content.content)
// 	postDC('Action', paramssPrint)
// }

const setLog = (error, level = 'error') => {
	try {
		const isDev = process?.env?.NODE_ENV === 'development'
		if (!isDev) {
			const params = {
				error: error,
				msg: '接口异常'
			}
			saveLogger(params, level)
		}
	} catch (err) {
		console.error(err)
	}
}
/**
 * candao.base.printFELogger 添加日志
 * @param body
 */
export const saveLogger = (body, logLevel = 'info') => {
	try {
		const isDev = process?.env?.NODE_ENV === 'development'
		if (isDev) {
			return
		}
		const userAgent = window.navigator.userAgent
		// 【1220】过滤Google 抓取工具（用户代理）产生的异常
		const reg = RegExp(/Google-Read-Aloud|APIs-Google|AdsBot-Google-Mobile|AdsBot-Google|Googlebot/)
		if (reg.test(userAgent)){
			return
		}

		const date = new Date()
		const params = {
			actionName: 'candao.base.printFELogger',
			content: {
				logLevel,
				content: {
					sign: 'JRG-KFC FE_Logger',
					v: '20230027',
					time: date.toString(),
					body,
					app: store.state.app,
					userAgent,
					page: window.location.href
				}
			},
		}

		if (window.history) {
			params.content.content.history = window.history?.state
		}
		if (store.state.app) {
			params.content.content.appInfo = store.state.appInfo
		}
		// console.log(params.content)
		params.content.content = JSON.stringify(params.content)

		// 去掉 candao.base.printFELogger 异常 触发的接口异常上报
		if (params.content.content.indexOf('candao.base.printFELogger') > -1) {
			return
		}

		postDC("Action", params);
	} catch (err) {
		console.error(err)
	}
}

const requestLogin = axios.create({
	baseURL,
	timeout: 30000,
	withCredentials: true,
})
// var loading;
// 请求拦截器
requestLogin.interceptors.request.use(
	config => {
		config.url += "?method=" + config.data.actionName
		if (store.state.app) {
			deviceId = store.state.deviceId
		}
		actionName = config.data.actionName
		config.data.key =getKeyData();
		config.data.content.crmCode = "azure";
		config.data.content.source = "Web";
		if (config.data.content.jrgAzureParams) {
			config.data.content.jrgAzureParams.deviceId = deviceId
			config.data.content.jrgAzureParams.crmCode = "azure";
			config.data.content.jrgAzureParams.brand = "KFCHK";
			config.data.content.jrgAzureParams.source = "Web";
		}
		config.data.content.brand = localStorage.getItem("locationCity")||'KFCHK';
		config.data.content.deviceId = deviceId
		if (config.data.content.device) {
			config.data.content.device = deviceId
		}
		config.data.langType = store.state.language == 2 ? 2 : 1
		if (store.state.language == 2) {
			config.data.content.language = "E"
		} else {
			config.data.content.language = "TC"
		}
		if (store.state.area == 1) {
			config.data.content.brand = localStorage.getItem("locationCity")||'KFCHK'
		}
		if (store.state?.isShowLoading && actionName != 'candao.base.printFELogger' && actionName != 'candao.preferential.canPreList') {
			loadingArr.push(Toast.loading({
				forbidClick: true,
				duration: 0
			}));
		}
		return config

	}, error => {
		return Promise.reject(error)
	}
);
// 响应拦截器
requestLogin.interceptors.response.use(
	res => {
		if (loadingArr.length) {
			loadingArr.splice(0, 1)[0].clear()
		}
		if (res.data.msg == "secretKey错误") {
			store.state.loginGet = 3
			return
		}
		return res.data
	},
	error => {
		if(error?.config && error.config.url.indexOf('candao.base.printFELogger')) {
			return
		}

		setLog(error)
		var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
		var btn = store.state.language == 2 ? "Confirm" : "確認"
		// Toast(errorTest)
		Dialog.alert({
			message: errorTest,
			confirmButtonText: btn
		}).then(() => {
			// on close
			window.location.reload();
		});
		if (loadingArr.length) {
			loadingArr.splice(0, 1)[0].clear()
		}

		return Promise.reject(error)
	}
)
// post请求
export function postLogin(url, params) {
	// url="/api/"+url
	if (!params.content.deviceId) {
		params.content.deviceId = deviceId
	}
	return requestLogin.post(
		url,
		params, {
			headers: {
				'content-type': 'multipart/form-data'
			},
		}
	)
}




// DC
const requestDC = axios.create({
	baseURL,
	timeout: 30000,
	withCredentials: true,
})
// 请求拦截器
requestDC.interceptors.request.use(
	config => {
		config.url += "?_name=" + config.data.actionName
		if (store.state.app) {
			deviceId = store.state.deviceId
		}
		config.data.key = getKeyData()
		config.data.content.crmCode = "azure";
		config.data.content.brand = "KFCHK";
		config.data.content.source = "Web";
		config.data.content.deviceId = deviceId
		if (config.data.content.jrgAzureParams) {
			config.data.content.jrgAzureParams.deviceId = deviceId;
			config.data.content.jrgAzureParams.crmCode = "azure";
			config.data.content.jrgAzureParams.brand = localStorage.getItem("locationCity")||'KFCHK';
			config.data.content.jrgAzureParams.source = "Web";
		}
		if (config.data.content.device) {
			config.data.content.device = deviceId
		}
		config.data.langType = store.state.language == 2 ? 2 : 1
		if (store.state.language == 2) {
			config.data.content.language = "E"
		} else {
			config.data.content.language = "TC"
		}
		if (store.state.area == 1) {
			config.data.content.brand = localStorage.getItem("locationCity")||'KFCHK'
		}
		if (store.state?.isShowLoading && config.data.actionName != 'candao.base.printFELogger' && config.data.actionName != 'candao.preferential.canPreList') {
			if(config.data.content.isHideLoading){
				delete config.data.content.isHideLoading
			} else {
				loadingArr.push(Toast.loading({
					forbidClick: true,
					duration: 0
				}));
			}
		}
    const userAgent = window.navigator.userAgent
    config.data.userAgent = userAgent;
    // system取值为APP/WEB，pass处理把data对象里的system放到content里面传给azure
    config.data.system = userAgent.indexOf('KFC-APP') > -1?'APP':'WEB';
    if (store.state.isYuu) {
      config.data.system = 'APP'
    }
		return config
	}, error => {
		return Promise.reject(error)
	}
);
// 响应拦截器
requestDC.interceptors.response.use(
	res => {
		try {
			if (loadingArr.length) {
				loadingArr.splice(0, 1)?.[0].clear()
			}
			if (res.data.msg == "secretKey错误") {
				store.state.loginGet = 3
				return
			}
		} catch (e) {
			console.error(e)
		}
		return res.data
	},
	error => {
		try {
			// setLog3(error, 3)
			if(error?.config && error.config.url.indexOf('candao.base.printFELogger')) {
				return
			}
			// setLog(error)

			var errorTest = store.state.language == 2 ? "Connection error has occurred, please retry" : "網絡異常，請重試"
			var btn = store.state.language == 2 ? "Confirm" : "確認"
			// Toast(errorTest)
			Dialog.alert({
				message: errorTest,
				confirmButtonText: btn
			}).then(() => {
				// on close
				window.location.reload();
			});
			if (loadingArr.length) {
				loadingArr.splice(0, 1)[0].clear()
			}
		} catch (e) {
			console.error(e)
		}


		return Promise.reject(error)
	}
)
// post请求
export function postDC(url, params) {
	// url="/api/"+url
	if (!params.content.deviceId) {
		params.content.deviceId = deviceId
	}
	return requestDC.post(
		url,
		params, {
			headers: {
				'content-type': 'multipart/form-data'
			},
		}
	)
}

// 没有代理
const requestPlace = axios.create({
	// baseURL: "https://maps.googleapis.com/maps/api/place/findplacefromtext/",

})
// 请求拦截器
requestPlace.interceptors.request.use(
	config => {
		return config
	}, error => {
		return Promise.reject(error)
	}
);
export function getPlace(url, params) {
	return requestPlace.get(url, {
		params
	})
}
export function postPlace(url, params) {
	return requestPlace.post(
		url,
		params,

	)
}
